<template>
  <v-container>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-container class="mx-0 px-0 ">
      <v-row align="right" v-if="factor!=null">
        <v-col cols="12" md="8" class="mt-0 pt-0">
          <v-card class="elevation-25">
            <v-card-title class="pt-4">
              <h3 class="card-title pmd-title-separator">{{ $t('$vuetify.factorsPage.PurchasedPackage') }}</h3>
            </v-card-title>
            <v-card-text>
              <v-data-table :items="factorItems" hide-default-footer :headers="headers">
                <!--                <template v-slot:top>
                                  <div class="title">
                                    <span>آیتم ها</span>
                                    <v-divider></v-divider>
                                  </div>
                                </template>-->
                <template v-slot:item.title="{item}">
                  <v-icon class="green--text">fas fa-heartbeat</v-icon>
                  {{ $vuetify.rtl ? item.title : item.en_title }}
                </template>
                <template v-slot:item.price="{item}">
                  <span>
                    <span v-if="!$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                    {{ localize(item.pivot.price) }}
                      <span v-if="$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                  </span>
                </template>
                <template v-slot:item.pivot.discount="{item}">
                     <span v-if="!$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                  {{ localize(item.pivot.discount) }}
                  <span v-if="$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                </template>
                <template v-slot:item.pivot.final_price="{item}">
                   <span v-if="!$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                  {{ localize(item.pivot.final_price) }}
                  <span v-if="$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                    </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text class="elevation-0 mt-2 px-0">

              <v-data-table v-if="factor!=null" :items="factor.payments" hide-default-footer
                            :headers="paymentsHeaders">
                <template v-slot:top>
                  <v-card-title class="pt-0">
                    <h3 class="card-title pmd-title-separator pt-0">{{ $t('$vuetify.CartComponent.Payments') }}</h3>
                  </v-card-title>
                </template>
                <template v-slot:item.type="{item}">
                  {{ resolvePaymentType(item.type) }}
                </template>
                <template v-slot:item.state="{item}">
                  {{ resolvePaymentState(item.state) }}
                </template>

                <template v-slot:item.final_amount="{item}">
                <span :dir="!$vuetify.rtl ? 'rtl' : ''">
                  <span v-if="!$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                  </span>
                  {{ (Number(item.final_amount).toLocaleString()) }}
                  <span v-if="$vuetify.rtl">
                      {{ $t(resolveCurrency(factor.currency)) }}
                  </span>
                </span>
                </template>
              </v-data-table>
            </v-card-text>
            <!--            <v-card-text class="elevation-1 mt-2">
                          <v-data-table
                              dense
                              :headers="invoiceHeaders"
                              :items="invoiceItems"
                              hide-default-footer
                              mobile-breakpoint="300"
                          >
                            <template v-slot:item="{item}">
                              <tr v-if="item.show">

                                <td class="text-left"
                                    :class="{'font-weight-bold final-price-item':item.final}">
                                  {{item.title}}
                                </td>
                                <td :class="{'font-weight-bold final-price-item':item.final}">
                                </td>
                                <td :class="{'font-weight-bold final-price-item':item.final}">
                                </td>
                                <td class="text-center"
                                    :class="{'font-weight-bold final-price-item':item.final}">
                                  {{item.price }}
                                </td>
                              </tr>
                            </template>

                          </v-data-table>
                        </v-card-text>-->
            <v-card-text>
              <v-row dense justify="center" align="center">
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                      v-if='factor.coupon_code!=null && factor.coupon_code != ""'
                      outlined
                      :value="factor.coupon_code"
                      readonly
                      :label="$t('$vuetify.factorsPage.tableHeaders[3]')"
                  >
                  </v-text-field>
                </v-col>

              </v-row>
            </v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="12" class="text-center">
                <template v-if="canUseWallet">
                  <v-card dense class="pa-0 ma-0 elevation-1 info">
                    <v-card-text class="pa-0 ma-0">
                      <div style="display: inline-block" class="white--text">
                        {{ walletMessage }}
                      </div>
                      <div style="display: inline-block" class="">
                        <v-switch v-model="applyMonetaryWallet"
                                  inset
                                  :readonly="isInDebt"
                                  color="white"
                                  class="mx-2 "></v-switch>
                      </div>
                      <div style="display: inline-block" class="white--text ">

                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>

          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-card>
            <v-card-text>
              <v-data-table
                  :headers="smallInvoiceHeaders"
                  :items="invoiceItems"
                  hide-default-footer
                  mobile-breakpoint="300"
              >
                <template v-slot:item="{item}">
                  <tr v-if="item.show">
                    <td class="text-right "
                        :class="{'font-weight-bold final-price-item':item.final}">
                      {{ item.title }}
                    </td>
                    <td class="text-center"
                        :class="{'font-weight-bold final-price-item':item.final}">
                        <span v-if="!$vuetify.rtl">
                            {{ $t(resolveCurrency(factor.currency)) }}
                        </span>
                        {{ item.price }}
                        <span v-if="$vuetify.rtl">
                            {{ $t(resolveCurrency(factor.currency)) }}
                        </span>
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col cols="12" class="text-right">

                  <v-dialog v-model="showOfflinePaymentDialog" max-width="800" scrollable

                  >
                    <ClientOfflinePayment @done="savingOfflineDone"
                                          type="factors"
                                          :cart="factor"
                                          :show="showOfflinePaymentDialog"
                                          @close="showOfflinePaymentDialog=false"
                    ></ClientOfflinePayment>
                  </v-dialog>

                  <template v-if="factor.canBePaid">
                    <div style="clear: both" class="text-center">
                      <v-btn class="elevation-25"
                             color="primary white--text"
                             block
                             rounded
                             @click="payFactor"
                             :disabled="!factor.canBePaidOnline"

                             v-if="factor.canBePaidOnline"
                             style="position:relative;"
                      >
                        <template v-if="factor.currency == 'dollar'">
                          {{ $t('$vuetify.preOrderPage.payDollar') }}
                          <span class="mx-1" style="text-transform: none;">(Credit Card / Debit Card)</span>
                        </template>
                        <template v-else-if="factor.currency == 'rial'">
                          {{ $t('$vuetify.preOrderPage.payRial') }}
                        </template>
                        <template v-else>
                          {{ $t('$vuetify.preOrderPage.pay') }}
                        </template>
                        <v-avatar size="28" class="absolute-payment-img">
                          <img :src="paymentMethodURL" alt="">
                        </v-avatar>
                      </v-btn>


                      <v-btn rounded block class="elevation-25 outlined-warning mt-2 ml-2" small
                             @click="showOfflinePaymentDialog = true">
                        {{ $t('$vuetify.factorsPage.PaymentType[2]') }}
                      </v-btn>
                      <!--<div></div>-->
                      <!--<v-btn class="elevation-0 mt-2 ml-2" small @click="showOfflinePayment">-->
                      <!--پرداخت آفلاین-->
                      <!--</v-btn>-->
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      <v-btn v-if="factor.state=='submit'" class="blue white--text"
                             block
                             @click="appendToOpenCart(factor)"
                      >

                        {{ $t('$vuetify.factorsPage.addToBasket') }}
                      </v-btn>
                      <!--<v-btn v-else-if="factor.state == 'paid'" class="green white&#45;&#45;text elevation-0" block>-->
                      <!--پرداخت شده-->
                      <!--</v-btn>-->

                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import {EventBus} from "@/event-bus";
import {mapGetters} from 'vuex';
import ClientOfflinePayment from '@/components/clients/ClientOfflinePayment';
import {resolveCurrency} from "../../utilities";

export default {
  components: {
    ClientOfflinePayment
  },
  created() {
    // this.checkDebtRecord();
    this.getFactor();

  },
  methods: {
    resolvePaymentState(state) {
      let result;
      switch (state) {
        case 'awaiting' :
          result = this.$t('$vuetify.factorsPage.PaymentState[0]')
          break;
        case 'failed':
          result = this.$t('$vuetify.factorsPage.PaymentState[1]');
          break;
        case 'paid':
          result = this.$t('$vuetify.factorsPage.PaymentState[2]');
          break;
        case 'rejected':
          result = this.$t('$vuetify.factorsPage.PaymentState[3]');
          break;
        case 'pending':
          result = this.$t('$vuetify.factorsPage.PaymentState[4]');
          break;
      }
      return result;
    },
    resolvePaymentType(input) {
      if (input == 'online') {
        return this.$t('$vuetify.factorsPage.PaymentType[0]')
      } else if (input == 'wallet_debt_settlement') {
        return this.$t('$vuetify.factorsPage.PaymentType[1]')
      } else if (input == 'offline') {
        return this.$t('$vuetify.factorsPage.PaymentType[2]')
      } else if (input == 'wallet_payment') {
        return this.$t('$vuetify.factorsPage.PaymentType[3]')
      }
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
    },
    appendToOpenCart(item) {
      EventBus.$emit('setLoading', true);

      let options = {
        factorId: item.id,
        userId: this.$store.getters.getUserInfo.id
      }

      this.$store.dispatch('payFactor', options).then((resp) => {
        let result = resp.data;
        if (result.state == 'SUCCESS') {

          if (result.state == 'SUCCESS') {
            this.$store.commit('setCart', result.result);
            this.$router.push({name: 'clientCart', params: {warning: result.warning}})
          }

        } else {
          EventBus.$emit('notify', 'red', undefined, resp.data.message);
        }

      }).catch((resp) => {
        console.log(resp);
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },

    savingOfflineDone() {
      this.showOfflinePaymentDialog = false;
      let message = this.$t('$vuetify.Notify.successfullyWithRedirect');
      setTimeout(() => {
        this.$router.push({name: 'dashboard', params: {lang: this.$vuetify.rtl ? 'fa' : 'en'}});
      }, 1000)

      EventBus.$emit('notify', 'green', undefined, message);
    },
    // checkDebtRecord(){
    //   this.$store.dispatch('clientCheckWalletDebt',{
    //       factorId: this.factor.id,
    //       payload: {}
    //   }).then((resp) => {
    //       console.log(resp);
    //   })
    // },
    handleDebt() {
      if (this.isInDebt) {
        this.applyMonetaryWallet = true;
      }
    },
    addWalletUsage() {
      return this.$store.dispatch('addWalletPaymentRecord', {
        factorId: this.factor.id,
        payload: {}
      }).then((resp) => {
        this.walletUsageId = resp.data.payment_id;
      })
    },
    removeWalletUsage() {

      return this.$store.dispatch('removeWalletPaymentRecord', {
        factorId: this.factor.id,
        payload: {payment_id: this.walletUsageId}
      }).then((resp) => {
        console.log(resp);
      }).catch((err) => {
        console.log(err);
      })


    },
    payFactor() {

      EventBus.$emit('setLoading', true);
      let options = {
        factorId: this.factor.id,
        userId: this.$store.getters.getUserInfo.id
      }
      this.$store.dispatch('payFactor', options).then((resp) => {
        let result = resp.data;

        if (result.state == 'SUCCESS') {

          if (!result.hasOwnProperty('url')) {
            if (result.state == 'SUCCESS') {
              this.$store.commit('setCart', result.result);
              this.$router.push({name: 'clientCart', params: {warning: result.warning}})
            }
          } else {
            window.location = result.url;
          }

        } else {
          EventBus.$emit('notify', 'red', undefined, resp.data.message);
        }

      }).catch((resp) => {
        console.log(resp);
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    localize(input) {
      return Number(input).toLocaleString();
    },
    getFactor() {
      this.$store.dispatch('clientGetSingleFactor', {factorId: this.factorId}).then((resp) => {

        this.factor = resp.data.factor;
        this.factorItems = resp.data.factor.packages;

        this.handleDebt();
        // if (this.isInDebt) {
        //     this.applyMonetaryWallet = true;
        // }
      })
    }
  },
  computed: {
    walletMessage() {
      let message = '';
      if (this.isInDebt) {
        message = this.$t('$vuetify.factorsPage.walletMessage.clearWallet');
      } else {
        message = this.$t('$vuetify.factorsPage.walletMessage.useWallet');
      }
      return message;
    },
    currencyInWallet() {
      return this.factor.currency in this.profile.monetary_wallet;
    },
    isInDebt() {
      if (this.currencyInWallet) {
        return this.profile.monetary_wallet[this.factor.currency].balance < 0;
      }
      return false;
    },
    canUseWallet() {
      if (this.currencyInWallet) {
        return this.profile.monetary_wallet[this.factor.currency].balance != 0;
      }
      return false;
    },
    ...mapGetters({
      profile: 'getUserInfo'
    }),
    paymentMethodURL() {

      switch (this.factor.currency) {
        case 'rial':
          return require('../../assets/zarinpalPayment.png');
        case 'dollar':
        case 'euro':
          return require('../../assets/paypalPaymentIcon.png');
          default:
            return null;
      }

    },
    invoiceItems() {
      return [
        {
          class: '',
          title: this.$t('$vuetify.CartComponent.invoiceItems.total'),
          price: this.localize(this.factor.final_amount),
          show: true
        },
        // {
        //     class: '',
        //     title: 'تخفیف',
        //     price: this.factor.discount_type != null ? (this.factor.discount_type == 'cart' ? this.localize(-this.factor.discount) : this.localize(-this.factor.items_discount)) : '',
        //     show: this.factor.discount_type != null
        // },

        {
          class: '',
          title: this.$t('$vuetify.factorsPage.status[1]'),
          price: this.localize(this.factor.paid_amount),
          show: true
        },
        {
          class: '',
          title: this.$t('$vuetify.factorsPage.tableHeaders[1]'),
          price: this.localize(this.factor.remaining_amount),
          show: true,
          final: true
        },
      ]
    },
  },
  data() {
    return {
      showOfflinePaymentDialog: false,
      walletUsageId: null,
      applyMonetaryWallet: false,
      paymentsHeaders: [
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[0]'), value: 'final_amount'},
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[1]'), value: 'type'},
        {text: this.$t('$vuetify.factorsPage.paymentsHeaders[2]'), value: 'state'},
      ],
      factorItems: [],
      factor: null,
      factorId: this.$route.params.factorId,
      headers: [
        {text: this.$t('$vuetify.CartComponent.headersTable[0]'), value: 'title', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[1]'), value: 'price', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[2]'), value: 'pivot.quantity', sortable: false},
        {text: this.$t('$vuetify.CartComponent.invoiceItems.offer'), value: 'pivot.discount', sortable: false},
        {text: this.$t('$vuetify.CartComponent.headersTable[3]'), value: 'pivot.final_price', sortable: false},
      ],
      invoiceHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'title',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'title',
        },
        {
          text: 'مبلغ',
          align: 'center',
          sortable: false,
          value: 'price',
        },
      ],
      smallInvoiceHeaders: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: this.$t('$vuetify.factorsPage.paymentsHeaders[0]'),
          align: 'center',
          sortable: false,
          value: 'price',
        }
      ],
      items: [
        {
          text: this.$t('$vuetify.ClientNavItem.dashboard'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.basket'),
          disabled: true,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/sessions`,
        }
      ]
    }
  },

  filters: {
    resolveCartState(state) {
      let result;
      switch (state) {
        case 'submit' :
          result = 'پیش فاکتور';
          break;
        case 'paid':
          result = 'پرداخت شده';
          break;
        case 'partially_paid':
          result = 'پیش پرداخت';
          break;
      }
      return result;
    },
  },
  watch: {
    applyMonetaryWallet(newVal) {
      if (newVal) {
        this.addWalletUsage().then(() => {
          this.getFactor();
        })
      } else {
        if (this.walletUsageId != null) {
          this.removeWalletUsage().then(() => {
            this.getFactor();
          });
        }
      }

    }
  }
}
</script>

<style scoped>

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

.final-price-item {
  border-top: .5px solid rgba(0, 0, 0, .4);
}

</style>